import { useState, useEffect } from "react";

import moment from "moment";

import { ReactComponent as e6MeterIcon } from "resources/test-types/up1b-e6-icon.svg";
import { ReactComponent as letByIcon } from "resources/test-types/let-by-icon.svg";
import { ReactComponent as newInstallationIcon } from "resources/test-types/new-install-icon.svg";
import { ReactComponent as u6G4MeterIcon } from "resources/test-types/up1b-u6g4-icon.svg";
import { ReactComponent as workingPressureIcon } from "resources/test-types/working-pressure-icon.svg";

import { DEFAULT_PRESSURE_TEST_ANALYSER_OPTIONS } from "config";

export function toCamelCase(s) {
    return s.replace(/[-_]([a-z])/gi, ($0, $1) => {
        return $1.toUpperCase();
    });
}

export function toSnakeCase(s) {
    return String(s)
        .replace(/^\w/, (c) => c.toLowerCase())
        .replace(/[A-Z]/g, ($1) => {
            return "_" + $1.toLowerCase();
        });
}

function toCapitalizedWords(name) {
    var words = name.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(capitalize).join(" ");
}

function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
}

export function isObject(o) {
    return o === Object(o) && !Array.isArray(o) && typeof o !== "function";
}

function doNotConvert(fieldName) {
    return fieldName === "externalUuids" || fieldName === "headers";
}

export function snakeToCamel(o) {
    if (isObject(o)) {
        const n = {};

        Object.keys(o).forEach((k) => {
            if (doNotConvert(k)) {
                n[toCamelCase(k)] = o[k];
            } else {
                n[toCamelCase(k)] = snakeToCamel(o[k]);
            }
        });

        return n;
    } else if (Array.isArray(o)) {
        return o.map((i) => {
            return snakeToCamel(i);
        });
    }

    return o;
}

export function camelToSnake(o) {
    if (isObject(o)) {
        const n = {};

        Object.keys(o).forEach((k) => {
            if (doNotConvert(k)) {
                n[toSnakeCase(k)] = o[k];
            } else {
                n[toSnakeCase(k)] = camelToSnake(o[k]);
            }
        });

        return n;
    } else if (Array.isArray(o)) {
        return o.map((i) => {
            return camelToSnake(i);
        });
    }

    return o;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(),
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export function delay(ms) {
    return new Promise((res) => setTimeout(res, ms));
}

export function formatTime(ms) {
    const seconds = Math.round(ms / 1000);
    if (seconds < 60) {
        return `${seconds} sec${seconds !== 1 ? "s" : ""}`;
    } else if (seconds < 3600) {
        return seconds % 60 === 0
            ? `${seconds / 60} min${seconds / 60 !== 1 ? "s" : ""}`
            : `${Math.floor(seconds / 60)} min${
                  Math.floor(seconds / 60) !== 1 ? "s" : ""
              }, ${seconds % 60} sec${seconds % 60 !== 1 ? "s" : ""}`;
    } else if (seconds < 86400) {
        return moment(ms).format("H:mm:ss");
    } else {
        return moment(ms).format("DD:H:mm:ss");
    }
}

const ONE_MINUTE = 60;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;

const plural = (value, string) => (value === 1 ? string : string + "s");

const getTimePart = (seconds, chunkSize, name) => {
    const fracPart = seconds % chunkSize;
    const intPart = Math.round((seconds - fracPart) / chunkSize);

    const parts = [`${intPart} ${plural(intPart, name)}`];

    if (fracPart) {
        parts.push(formatSeconds(fracPart));
    }

    return parts.join(", ");
};

export function formatSeconds(seconds) {
    if (seconds < ONE_MINUTE) {
        return getTimePart(seconds, 1, "sec");
    } else if (seconds < ONE_HOUR) {
        return getTimePart(seconds, ONE_MINUTE, "min");
    } else if (seconds < ONE_DAY) {
        return getTimePart(seconds, ONE_HOUR, "hour");
    } else {
        return getTimePart(seconds, ONE_DAY, "day");
    }
}

export function formatChartData(
    readings,
    readingTestStart,
    readingTestEnd,
    readingStabilisationStart,
    domain,
    readingTestFailed,
    incidentReadings = [],
    offsetTime,
) {
    let maxVal = -99999;
    let maxValidVal = -99999;
    let offset = 0;

    let closestValToLowerBound;
    let closestValToUpperBound;
    const xBuffer = (domain.x[1] - domain.x[0]) / 10;

    if (offsetTime) {
        offset = offsetTime - Date.parse(readingTestStart?.measuredAt);
    }

    const testStartTime = Date.parse(readingTestStart?.measuredAt) + offset;
    const testEndTime = Date.parse(readingTestEnd?.measuredAt) + offset;
    const stabilisationStartTime =
        readingStabilisationStart &&
        Date.parse(readingStabilisationStart?.measuredAt) + offset;

    let formattedReadings = readings
        .map((reading) => {
            let readingTime = Date.parse(reading.x) + offset;
            let readingVal = Math.max(reading.y, 0);
            const pointOfFailure = reading.x === readingTestFailed?.measuredAt;

            if (readingVal > maxVal) {
                maxVal = readingVal;
            }

            if (
                readingTime >= domain.x[0] - xBuffer &&
                readingTime <= domain.x[1] + xBuffer
            ) {
                if (readingVal > maxValidVal) {
                    maxValidVal = readingVal;
                }

                return { x: readingTime, y: readingVal, pointOfFailure };
            } else {
                if (
                    !closestValToLowerBound ||
                    (readingTime >= closestValToLowerBound?.x &&
                        readingTime <= domain.x[0] - xBuffer)
                ) {
                    closestValToLowerBound = {
                        x: readingTime,
                        y: readingVal,
                    };
                } else if (
                    !closestValToUpperBound ||
                    (readingTime <= closestValToUpperBound?.x &&
                        readingTime >= domain.x[1] + xBuffer)
                ) {
                    closestValToUpperBound = {
                        x: readingTime,
                        y: readingVal,
                    };
                }
            }

            return null;
        })
        .filter((n) => n);

    let testPeriodData = [];
    let shadedData = [];
    let data = [];
    const k =
        formattedReadings.length > 200
            ? Math.ceil(formattedReadings.length / 200)
            : 1;

    formattedReadings.forEach((item, i) => {
        if (i % k === 0) {
            data.push(item);
        }

        if (item.x >= (stabilisationStartTime ?? testStartTime)) {
            shadedData.push(item);
        }

        if (item.x >= testStartTime && item.x <= testEndTime) {
            testPeriodData.push(item);
        }
    });

    if (closestValToLowerBound) {
        data.push(closestValToLowerBound);
    }

    if (closestValToUpperBound) {
        data.push(closestValToUpperBound);
    }

    const testStartDot = { x: testStartTime, y: readingTestStart?.measurement };
    const testEndDot = { x: testEndTime, y: readingTestEnd?.measurement };
    const stabilisationDot = readingStabilisationStart && {
        x: stabilisationStartTime,
        y: readingStabilisationStart.measurement,
    };
    const testFailedDot = readingTestFailed && {
        x: Date.parse(readingTestFailed.measuredAt),
        y: readingTestFailed.measurement,
    };

    const events = incidentReadings.map((i) => ({
        x: Date.parse(i.measuredAt) + offset,
        y: i.measurement,
        type: i.type,
        size: 5,
    }));

    const { sample, prediction } = analyseTest(
        readings,
        readingTestStart,
        readingTestEnd,
        readingStabilisationStart,
        DEFAULT_PRESSURE_TEST_ANALYSER_OPTIONS,
    );

    return {
        data,
        shadedData,
        testPeriodData,
        testStartDot,
        testEndDot,
        stabilisationDot,
        testFailedDot,
        domain: {
            y: domain.y[0] && domain.y[1] ? domain.y : [0, maxValidVal],
            x: [
                Math.max(domain.x[0], Date.parse(readings[0].x)),
                Math.min(
                    domain.x[1],
                    Date.parse(readings[readings.length - 1].x),
                ),
            ],
        },
        fractionalData: maxValidVal / 10,
        maxY: maxVal,
        events,
        sample,
        prediction,
    };
}

const calculateLineOfBestFitAndError = (data) => {
    const n = data.length;

    const startX = data[0].x;
    const endX = data[data.length - 1].x;

    // Calculate the sum of x, y, x^2, and xy for the data points
    let sumX = 0;
    let sumY = 0;
    let sumX2 = 0;
    let sumXY = 0;

    for (let i = 0; i < n; i++) {
        const { x: xPreShift, y } = data[i];
        const x = xPreShift - startX;

        sumX += x;
        sumY += y;
        sumX2 += x * x;
        sumXY += x * y;
    }

    // Calculate the slope (m) and y-intercept (b) of the line of best fit
    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const yIntercept = (sumY - slope * sumX) / n;

    let squaredErrorsSum = 0;
    for (let i = 0; i < n; i++) {
        const { x: xPreShift, y } = data[i];
        const x = xPreShift - startX;
        const predictedY = slope * x + yIntercept;
        const error = y - predictedY;
        squaredErrorsSum += error * error;
    }

    const rmse = Math.sqrt(squaredErrorsSum / n);

    return {
        start: {
            x: data[0].x,
            y: yIntercept,
        },
        end: {
            x: data[data.length - 1].x,
            y: slope * (endX - startX) + yIntercept,
        },
        gradient: slope,
        rmse,
    };
};

const registerLineSegmentIfStraightLine = (
    lineSegment,
    options,
    detectedStablePeriods,
) => {
    if (lineSegment.length >= options.minimumStablePeriodLength) {
        const lineOfBestFit = calculateLineOfBestFitAndError(lineSegment);
        if (lineOfBestFit.rmse < options.maximumRootMeanSquaredError) {
            detectedStablePeriods.push(lineOfBestFit);
        }
    }
};

const convertTimestringToInteger = (point) => ({
    x: new Date(point.x).getTime(),
    y: point.y,
});

const calculateDifferential = (point, index, array) => {
    let dY, dX;

    if (index === array.length - 1) {
        dY = point.y - array[index - 1].y;
        dY = point.x - array[index - 1].x;
    } else {
        dY = array[index + 1].y - point.y;
        dX = array[index + 1].x - point.x;
    }

    return { ...point, diff: (1000 * dY) / dX };
};

const calculateSecondDifferential = (point, index, array) => {
    let dY, dX;

    if (index === array.length - 1) {
        dY = point.diff - array[index - 1].diff;
        dY = point.x - array[index - 1].x;
    } else {
        dY = array[index + 1].diff - point.diff;
        dX = array[index + 1].x - point.x;
    }

    return { ...point, diffDiff: (1000 * dY) / dX };
};

const detectLinearPeriods = (
    data,
    options,
    detectedStablePeriods,
    startTime,
) => {
    let lineSegment = [];
    let accumulativeError = 0;
    for (const point of data) {
        lineSegment.push(point);

        if (
            Math.abs(point.diffDiff) < options.secondDifferentialNoiseWindow &&
            Math.abs(accumulativeError) <
                options.cumulativeSecondDifferentialNoiseWindow &&
            point.x < startTime
        ) {
            accumulativeError += point.diffDiff;
        } else {
            registerLineSegmentIfStraightLine(
                lineSegment,
                options,
                detectedStablePeriods,
            );
            lineSegment = [];
            accumulativeError = 0;
        }
    }

    registerLineSegmentIfStraightLine(
        lineSegment,
        options,
        detectedStablePeriods,
    );
};

const predictFinalPressure = (
    readingTestStart,
    readingTestEnd,
    stablePeriod,
) => {
    if (!(readingTestStart && stablePeriod)) {
        return null;
    }

    return (
        readingTestStart.measurement +
        stablePeriod.gradient *
            (new Date(readingTestEnd.measuredAt).getTime() -
                new Date(readingTestStart.measuredAt).getTime())
    );
};

const getBestDetectedStablePeriod = (start, detectedStablePeriods, options) => {
    const { bestStableDetectionPeriodSelectionAlgorithm } = options;
    const testStartedAt = new Date(start).getTime();
    const stablePeriodsBeforeTestStarted = detectedStablePeriods.filter(
        (line) => line.start.x < testStartedAt,
    );

    if (stablePeriodsBeforeTestStarted.length === 0) {
        return null;
    }

    let bestStablePeriod = null;
    switch (bestStableDetectionPeriodSelectionAlgorithm) {
        case "first":
            bestStablePeriod = stablePeriodsBeforeTestStarted[0];
            break;

        case "lowestrmse":
            bestStablePeriod = stablePeriodsBeforeTestStarted.reduce((a, b) => {
                return a.rmse < b.rmse ? a : b;
            });
            break;

        case "longest":
        default:
            bestStablePeriod = stablePeriodsBeforeTestStarted.reduce((a, b) => {
                const aLength = a.end.x - a.start.x;
                const bLength = b.end.x - b.start.x;

                return aLength > bLength ? a : b;
            });
            break;
    }

    return bestStablePeriod;
};

const analyseTest = (
    readings,
    readingTestStart,
    readingTestEnd,
    readingStabilisationStart,
    options,
) => {
    if (!readingStabilisationStart) return {};

    const detectedStablePeriods = [];

    const stabilisationStart = new Date(
        readingStabilisationStart.measuredAt,
    ).getTime();
    const data = readings
        .map(convertTimestringToInteger)
        .filter((p) => p.x >= stabilisationStart)
        .map(calculateDifferential)
        .map(calculateSecondDifferential);

    detectLinearPeriods(
        data,
        options,
        detectedStablePeriods,
        new Date(readingTestStart.measuredAt).getTime(),
    );

    const sample = getBestDetectedStablePeriod(
        readingTestStart.measuredAt,
        detectedStablePeriods,
        options,
    );

    const prediction = predictFinalPressure(
        readingTestStart,
        readingTestEnd,
        sample,
    );

    return { sample, prediction };
};

export function formatMs(ms) {
    return moment(ms).format("HH:mm:ss");
}

export function roundPressure(mbar) {
    if (isNaN(mbar) || mbar === null) {
        return "No Data";
    }
    return mbar.toFixed(2);
}

export function identicalObject(oldObject, updatedObject) {
    if (oldObject === updatedObject) {
        return true;
    }

    if (!oldObject || !updatedObject) {
        return false;
    }

    if (Object.keys(oldObject).length !== Object.keys(updatedObject).length) {
        return false;
    }

    return Object.keys(oldObject).every(
        (key) => oldObject[key] === updatedObject[key],
    );
}

const ICONS = {
    "LET-BY": letByIcon,
    U6G4: u6G4MeterIcon,
    E6: e6MeterIcon,
    "WORKING-PRESSURE": workingPressureIcon,
    "NEW-INSTALLATION": newInstallationIcon,
};

export function renderIcon(icon, color, className) {
    const Icon = ICONS[icon?.toUpperCase()] ?? e6MeterIcon;

    return <Icon fill={color ?? "#555"} className={className} />;
}

export function convertBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
}

export function downloadFile(data, strFileName, strMimeType) {
    var self = window, // this script is only for browsers anyway...
        u = "application/octet-stream", // this default mime also triggers iframe downloads
        m = strMimeType || u,
        x = data,
        D = document,
        a = D.createElement("a"),
        z = function (a) {
            return String(a);
        },
        B = self.Blob || self.MozBlob || self.WebKitBlob || z,
        BB = self.MSBlobBuilder || self.WebKitBlobBuilder || self.BlobBuilder,
        fn = strFileName || "download",
        blob,
        b,
        ua, //eslint-disable-line no-unused-vars
        fr;

    //if(typeof B.bind === 'function' ){ B=B.bind(self); }

    if (String(this) === "true") {
        //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
        x = [x, m];
        m = x[0];
        x = x[1];
    }

    //eslint-disable-next-line no-useless-escape
    if (String(x).match(/^data\:[\w+\-]+\/[\w+\-]+[,;]/)) {
        return navigator.msSaveBlob // IE10 can't do a[download], only Blobs:
            ? navigator.msSaveBlob(d2b(x), fn)
            : saver(x); // everyone else can save dataURLs un-processed
    } //end if dataURL passed?

    try {
        blob = x instanceof B ? x : new B([x], { type: m });
    } catch (y) {
        if (BB) {
            b = new BB();
            b.append([x]);
            blob = b.getBlob(m); // the blob
        }
    }

    function d2b(u) {
        var p = u.split(/[:;,]/),
            t = p[1],
            dec = p[2] === "base64" ? atob : decodeURIComponent,
            bin = dec(p.pop()),
            mx = bin.length,
            i = 0,
            uia = new Uint8Array(mx);

        for (i; i < mx; ++i) uia[i] = bin.charCodeAt(i);

        return new B([uia], { type: t });
    }

    function saver(url, winMode) {
        if ("download" in a) {
            //html5 A[download]
            a.href = url;
            a.setAttribute("download", fn);
            a.innerHTML = "downloading...";
            D.body.appendChild(a);
            setTimeout(function () {
                a.click();
                D.body.removeChild(a);
                if (winMode === true) {
                    setTimeout(function () {
                        self.URL.revokeObjectURL(a.href);
                    }, 250);
                }
            }, 66);
            return true;
        }

        //do iframe dataURL download (old ch+FF):
        var f = D.createElement("iframe");
        D.body.appendChild(f);
        if (!winMode) {
            //eslint-disable-next-line no-useless-escape
            url = "data:" + url.replace(/^data:([\w\/\-\+]+)/, u);
        }

        f.src = url;
        setTimeout(function () {
            D.body.removeChild(f);
        }, 333);
    } //end saver

    if (navigator.msSaveBlob) {
        // IE10+ : (has Blob, but not a[download] or URL)
        return navigator.msSaveBlob(blob, fn);
    }

    if (self.URL) {
        // simple fast and modern way using Blob and URL:
        saver(self.URL.createObjectURL(blob), true);
    } else {
        // handle non-Blob()+non-URL browsers:
        if (typeof blob === "string" || blob.constructor === z) {
            try {
                return saver("data:" + m + ";base64," + self.btoa(blob));
            } catch (y) {
                return saver("data:" + m + "," + encodeURIComponent(blob));
            }
        }

        // Blob but not URL:
        fr = new FileReader();
        fr.onload = function (e) {
            saver(this.result);
        };
        fr.readAsDataURL(blob);
    }
    return true;
}

const getCoordinates = (point, orientation, height, width, yOffset) => {
    switch (orientation) {
        case "left":
            return [
                { x: point.x - width, y: point.y + height / 2 },
                { x: point.x, y: point.y - height / 2 },
            ];
        case "right":
            return [
                { x: point.x, y: point.y + height / 2 },
                { x: point.x + width, y: point.y - height / 2 },
            ];
        case "top":
            return [
                { x: point.x - width / 2, y: point.y + height + yOffset },
                { x: point.x + width / 2, y: point.y + yOffset },
            ];
        case "bottom":
            return [
                { x: point.x - width / 2, y: point.y - yOffset },
                { x: point.x + width / 2, y: point.y - yOffset - height },
            ];
    }
};

const isColliding = (label1, label2) => {
    const [{ x: left1, y: top1 }, { x: right1, y: bottom1 }] = label1;
    const [{ x: left2, y: top2 }, { x: right2, y: bottom2 }] = label2;

    // The first rectangle is under the second or vice versa
    if (top1 < bottom2 || top2 < bottom1) {
        return false;
    }
    // The first rectangle is to the left of the second or vice versa
    if (right1 < left2 || right2 < left1) {
        return false;
    }

    return true;
};

const checkCollision = (coordinates, labels) => {
    return labels.some((label) => isColliding(coordinates, label));
};

const checkExceedingBounds = (coordinates, domain) => {
    const [{ x: minX, y: maxY }, { x: maxX, y: minY }] = coordinates;

    return !(minX >= domain.x[0] && maxX <= domain.x[1] && minY >= domain.y[0]);
};

const checkValidity = (coordinates, labels, domain) => {
    const collisionDetected = checkCollision(coordinates, labels);
    const outOfBounds = checkExceedingBounds(coordinates, domain);

    return !(collisionDetected || outOfBounds);
};

const getNewPoint = (point, currentOrientation, dependentAxis, domain) => {
    if (typeof dependentAxis === "function") {
        const safeOrientation = ["top", "bottom"][getRandomInteger()];
        const domainDifference = domain.x[1] - domain.x[0];
        const safeX = domain.x[0] + Math.random() * domainDifference;

        return { safeX, safeY: dependentAxis(safeX), safeOrientation };
    }

    if (dependentAxis === "x") {
        const safeOrientation = ["left", "right"][getRandomInteger()];
        const domainDifference = domain.y[1] - domain.y[0];
        const safeY = domain.y[0] + Math.random() * domainDifference;

        return { safeX: point.x, safeY, safeOrientation };
    } else {
        const safeOrientation = ["top", "bottom"][getRandomInteger()];
        const domainDifference = domain.x[1] - domain.x[0];
        const safeX = domain.x[0] + Math.random() * domainDifference;

        return { safeX, safeY: point.y, safeOrientation };
    }
};

function getRandomInteger(n = 2) {
    return Math.floor(Math.random() * n);
}

export const getSafeLabel = (
    x,
    y,
    dependentAxis,
    orientation,
    height,
    width,
    labels,
    domain,
    yOffset,
) => {
    let safePoint = { safeX: x, safeY: y, safeOrientation: orientation };
    let coordinates = getCoordinates(
        { x, y },
        orientation,
        height,
        width,
        yOffset,
    );

    let validCoords = checkValidity(coordinates, labels, domain);
    let attempts = 0;
    while (!validCoords && attempts <= 100) {
        safePoint = getNewPoint(
            { x: safePoint.safeX, y: safePoint.safeY },
            safePoint.safeOrientation,
            dependentAxis,
            domain,
        );
        attempts = attempts + 1;

        coordinates = getCoordinates(
            { x: safePoint.safeX, y: safePoint.safeY },
            safePoint.safeOrientation,
            height,
            width,
            yOffset,
        );
        validCoords = checkValidity(coordinates, labels, domain);
    }

    return validCoords ? { coordinates, ...safePoint } : {};
};
